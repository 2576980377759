export const content = {
  hero: {
    heading: "Alex Billson",
    caption: "://developer",
    intro: "Software engineer, coffee enthusiast",
  },
  biscuit: {
    heading: "Biscuit",
    caption: "cavapoo, hecking good boy",
    intro:
      "He likes toys that squeak, yakker chews and being all in your business",
  },
  work: {
    heading: "Work",
    caption: "typing, clicking, stackoverflow-ing, caffeinating",
    intro: "Coming soon ...",
  },
  cool: {
    heading: "Something cool",
    caption: "trust me",
    intro: "Coming soon ...",
  },
}
